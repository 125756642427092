import {useState} from "react";
import React from 'react';
import "./Podcasts.css";
import { MetaTags } from "react-meta-tags";
// import twitter from './images/iconmonstr-twitter-3.svg';
// import instagram from './images/iconmonstr-instagram-13.svg';
// import youtube from './images/iconmonstr-youtube-8.svg';
// import spotify from './images/iconmonstr-spotify-3.svg';
// import soundcloud from './images/iconmonstr-soundcloud-3.svg';
import { Container, Row, Col, Button } from 'react-bootstrap';


import Select from "react-select";

function Podcasts(props) {
  const [eptags, setEptags] = useState({
                                        ep1: ['CH', 'QUAL', 'epi', 'PM', 'HH', 'AE'], 
                                        ep2: ['CH', 'QUAL', 'AP', 'epi', 'PM', 'PH', 'ENG', 'AE'], 
                                        ep3: ['QUAL', 'PM', 'PH', 'ENG', 'AE'],
                                        ep4: ['WP','CEC','WWE','epi', 'AE'],
                                        ep5: ['PH', 'AP', 'ENG', 'OS', 'HH', 'AE'],
                                        ep6: ['GIS', 'GM', 'PH', 'AE'],
                                        ep7: ['epi', 'HIM', 'DI', 'HH','AE'],
                                        ep8: ['CEC', 'HH','AE']
                                      });

  const [tag, setTag] = useState('Choose Topic...')

  const handleChange = (e) => {
    setTag({id:e.id, name:e.label});
   };
 
  const visible = (ep, tag) => {
    if (tag == topic) {
      return "100%"
    } else {
    if (eptags[ep].includes(tag.id)) {
      return "100%"
    } else {
      return "0%"
    }
  }
  };

  const [topic, setTopic] = useState('Choose Topic...');

  const customStyles = {
    control: (base, state) => ({
      ...base,
    //   background: "#023950",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      textAlign: "center",
      // Overwrittes the different states of border
    //   borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
      hyphens: "auto",
      // kill the gap
      marginTop: 0,
      textAlign: "center",
      // prevent menu to scroll y
      wordWrap: "break-word"
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };


var options = [
    {
        "label": "Air pollution",
        "id": "AP",
    },
    {
        "label": "Epidemiology",
        "id": "epi",
    },
    {
        "label": 'Machine learning',
        "id": "ML",
    },
    {
      "label": 'Public health',
      "id": "PH",
    },
    {
      "label": 'Water Pollution', 
      "id": "WP"
    }, 
    {
      "label": 'Chemicals of emerging concern',
      "id": 'CEC'
    }, 
    {
      "label": 'Wastewater Epidemiology', 
      "id": 'WWE'
    },
    {
      "label": 'Generative models',
      "id": "GM",
    },
    {
      "label": 'Open source',
      "id": "OS",
    },
    {
      "label": 'Engagement',
      "id": "ENG",
    },
    {
      "label": 'GIS',
      "id": "GIS",
    },
    {
      "label": 'Child health',
      "id": "CH",
    },
    {
      "label": 'Human health',
      "id": "HH",
    },
    {
      "label": 'Qualitative',
      "id": "QUAL",
    },
    {
      "label": 'Participatory methods',
      "id": "PM",
    },
    {
      "label": 'Diabetes',
      "id": "DI",
    },
    {
      "label": 'Health Impact Modelling',
      "id": "HIM",
    },
    {
      "label": 'All episodes',
      "id": "AE",
    }
    
  ];



  return (
    <div className='body'>
      <MetaTags>
        <title>The Envirohealth Podcast</title>
        <meta name="description" content="Envirohealth presents..." />
        <meta property="og:title" content="The Envirohealth Podcast" />
      </MetaTags>
      <Col>
      <Row>
        <div className='drop-down'>
            <Select className='select'
              options = {options}
              getOptionValue={option=>option.label}
              styles={customStyles}
              placeholder={topic}
              onChange = {handleChange}
              />
        </div>
        </Row>

          {/* <Row> */}
          <iframe src="https://open.spotify.com/embed/episode/6D62kRTNO6U4zf3qfHESUF?utm_source=generator" title='ep1' width={visible('ep1', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>
          <iframe src="https://open.spotify.com/embed/episode/3iAmhuK4FlrwJyv8M502Eg?utm_source=generator" title='ep2' width={visible('ep2', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>
          <iframe src="https://open.spotify.com/embed/episode/7vSF7MRVkoC0xI4qSujZDt?si=p3uE4cOsQDe5lkJE7H9cgw?utm_source=generator" title='ep3'width={visible('ep3', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>       
         
          <iframe src="https://open.spotify.com/embed/episode/3KSqW6sSpd5RnQhu3sVoOf?si=AAgc4UQUSle47dqKVq9Piw?utm_source=generator" title='ep4'width={visible('ep4', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>       
          
          <iframe src="https://open.spotify.com/embed/episode/2ltUUXkTGnM1TOYqPhPaBS?si=D-5regQ7TjyoLPSQ7Ck9VQ?utm_source=generator" title='ep5'width={visible('ep5', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>       
          
          <iframe src="https://open.spotify.com/embed/episode/3zV5VuvWqRkIx8VPVV1IgM?utm_source=generator" title='ep6' width={visible('ep6', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>       

          <iframe src="https://open.spotify.com/embed/episode/5ibQmTPksXdFAxZxEDwd6t?si=zUDsn0RMTZiRW8uVlzhgZg?utm_source=generator" title='ep7' width={visible('ep7', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>     
          
          <iframe src="https://open.spotify.com/embed/episode/0hpf0HwefQOcL9Az0OEv8r?si=yEyTLlzyTWyqus-G0xCjtw?utm_source=generator" title='ep8' width={visible('ep8', tag)} height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media"></iframe>       

          {/* <iframe class="iframe-placeholder" title='int' width="50%" height="232" frameBorder="0" allowtransparency="true" style={{visibility: ''}} allow="encrypted-media">Launch</iframe>        */}
          {/* </Row> */}
      </Col>
</div>
);
}

export default Podcasts;

